/* eslint-disable sonarjs/cognitive-complexity */
import classNames from 'classnames';
import { get, truncate } from 'lodash';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import {
  CellType,
  ITableCellProps,
  MenuOption,
  MenuOptionProps,
  TableHeadersType,
  TableIdsType,
} from '../../types/table';
import SkeletonRow from '../skeletons/SkeletonRow';
import Pagination from './PaginationBar';
import TableCell from './TableCell';
import { TableContext } from './TableContext';
import TableRow from './TableRow';

const getAlignment = (align?: 'right' | 'left' | 'center') => {
  return align === 'right'
    ? 'text-right justify-end pr-3 md:pr-5'
    : align === 'center'
    ? 'text-center justify-center'
    : 'text-left justify-start pl-3 md:pl-5';
};

export interface BodyProps {
  tableId?: TableIdsType;
  tableName: string;
  headers: TableHeadersType[];
  data: any[] | null;
  isLoading?: boolean;
  total: number;
  rowLimit: number;
  start?: number;
  setStart?: (x: number) => void;
  pagination?: boolean;
  fillEmptyRows?: boolean;
  rowLink?: string | ((row: any) => string);
  // ======================
  // row menu
  // ======================
  enableRowMenu?: boolean;
  menuOptions?: (props: MenuOptionProps) => MenuOption[];
  labelSelector?: string;
  // ======================
  // multiselect
  // ======================
  enableRowCheckbox?: boolean;
  onCheck?: <T = Record<string, any>>(checked: boolean, row: T) => void;
  setReset?: Dispatch<SetStateAction<(() => void) | undefined>>;
  error?: string;
  compact?: boolean;
  alertType?: ITableCellProps['alertType'];
}

const Body: FC<BodyProps> = ({
  data,
  enableRowCheckbox,
  enableRowMenu,
  error,
  fillEmptyRows,
  headers,
  isLoading,
  labelSelector = 'company_name',
  menuOptions,
  onCheck,
  pagination,
  rowLimit,
  rowLink,
  setReset,
  setStart,
  start,
  tableId,
  tableName,
  total,
  compact,
  alertType,
}) => {
  const {
    playwrightId,
    theme = 'light',
    selectedRows,
    setSelectedRows,
  } = React.useContext(TableContext);
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'content');

  const [page, setPage] = React.useState(
    (start && rowLimit && start / rowLimit + 1) || 1
  );
  const [maxPages, setMaxPages] = React.useState(1);
  const [tableTotal, setTableTotal] = React.useState(total);

  // const maxPages = Math.ceil(tableTotal / limit);
  const length = data?.length || 0;

  const blankRows = rowLimit ? rowLimit - length : 10 - length;

  React.useEffect(() => {
    !isLoading && total !== tableTotal && setTableTotal(total);
    !isLoading && total && rowLimit && setMaxPages(Math.ceil(total / rowLimit));
  }, [total, isLoading]);

  React.useEffect(() => {
    // bit hacky, but since the modals are outside the scope of this component, we need to reset the selected rows when the modal is closed
    setReset?.(() => () => setSelectedRows([]));
  }, []);

  /**
   * The checkbox has its own onCheck handler which updates the Table's internal context.
   * This handler is an optional extra for the parent component to use.
   * For example, updating the selected companies in a table to pass to a modal.
   */
  const handleCheck = (checked: boolean, row: any) => {
    onCheck?.(checked, row);
  };

  // PAGINATION

  const handlePageChange = (page: number) => setPage(page);

  const handlePageDown = () => setPage(prev => (prev > 1 ? prev - 1 : prev));

  const handlePageUp = () =>
    setPage(prev => (prev < maxPages ? prev + 1 : prev));

  React.useEffect(() => {
    if (setStart) {
      setStart((page - 1) * rowLimit);
    }
  }, [page]);

  React.useEffect(() => {
    if (maxPages && start) {
      setPage(start / rowLimit + 1 || 1);
    }
  }, [maxPages]);

  const makeCellType = (selector: string | ((row: any) => any)): CellType =>
    selector === 'CHECKBOX' && enableRowCheckbox
      ? 'CHECKBOX'
      : selector === 'MENU' && enableRowMenu
      ? 'MENU'
      : selector === 'ALERT'
      ? 'ALERT'
      : 'DATA';

  const tableTheme = {
    head: {
      dark: 'bg-primary text-white',
      light: 'bg-gray-200 text-primary',
    },
  };

  return (
    <div className="overflow-hidden">
      <div className="overflow-auto">
        <table
          className={`min-w-full overflow-auto text-xs table-fixed md:text-sm`}
          style={{ maxHeight: (rowLimit + 1) * 48 }}
          id={tableName}
          {...setPlaywrightId('table')}
        >
          <thead
            className={classNames(tableTheme.head[theme])}
            {...setPlaywrightId('head')}
          >
            <tr
              className={classNames('w-full font-semibold', {
                'h-[35px]': compact,
              })}
              {...setPlaywrightId('head.row')}
            >
              {headers.map(({ name, align = 'left', width = '' }, index) => {
                const contentAlignment = getAlignment(align);
                return (
                  <th
                    key={`table-head-${name}-${index}`}
                    scope="col"
                    className={classNames(
                      contentAlignment,
                      width,
                      `whitespace-no-wrap truncate`,
                      compact ? 'py-1' : 'py-3'
                    )}
                    {...setPlaywrightId(`head.row.th-${index}`)}
                  >
                    {name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody {...setPlaywrightId('body')}>
            {isLoading && (
              <SkeletonRow
                cellQty={headers.length}
                className={classNames(
                  'even:bg-gray-50 odd:bg-gray-100 animate-pulse',
                  compact ? 'h-[35px]' : 'h-[48px]'
                )}
                widths={headers.map(header => header.width)}
                rowQty={rowLimit}
              />
            )}

            {!isLoading && tableTotal === 0 && (
              <tr
                style={{
                  height: rowLimit * (compact ? 35 : 48),
                }}
              >
                <td
                  colSpan={headers?.length}
                  className={classNames(
                    'relative max-w-xs text-center bg-gray-50',
                    {
                      'p-3': compact,
                    }
                  )}
                >
                  {error ? (
                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                      <div className="max-w-md mx-auto">{error}</div>
                    </div>
                  ) : (
                    <span>No {tableName}</span>
                  )}
                </td>
              </tr>
            )}

            {!isLoading &&
              tableTotal > 0 &&
              data?.map((row, rowIndex) => (
                <TableRow
                  key={`table-row-${rowIndex}`}
                  rowIndex={rowIndex}
                  playwrightId={setPlaywrightId(`body.row-${rowIndex}`)}
                  compact={compact}
                >
                  {headers.map(
                    (
                      {
                        align,
                        selector,
                        contentClassName,
                        rowTitle,
                        width,
                        name,
                        truncateLength,
                      },
                      i
                    ) => {
                      const value =
                        typeof selector === 'function'
                          ? selector(row, rowIndex)
                          : row[selector];

                      const contentClass =
                        typeof contentClassName === 'function'
                          ? contentClassName(row)
                          : contentClassName;

                      const link =
                        typeof rowLink === 'function' ? rowLink(row) : rowLink;

                      const title =
                        typeof rowTitle === 'function'
                          ? rowTitle(row)
                          : rowTitle || name;

                      const contentAlignment = getAlignment(align);

                      const cellType = makeCellType(selector);

                      const ellipsisLabel = truncate(
                        get(row, `${labelSelector}`),
                        {
                          length: truncateLength ?? 13,
                        }
                      );

                      // if table is for reports, row.id is the report UUID and row.company_uuid is the company UUID
                      // if table is for companies, row.id is the company UUID
                      // if table is for portfolios, row.id is the portfolio UUID

                      // const canAddToPortfolio = allowAddToPortfolio(row);
                      let canAddToPortfolio = false;
                      let companyUUID = '';
                      let rowId = ''; // rowId is used for the bulk actions as an array of ids

                      if (tableId === 'COMPANIES') {
                        canAddToPortfolio = !!row.id;
                        companyUUID = row.company_uuid || row.id;
                        rowId = row.company_uuid || row.id;
                      }

                      if (tableId === 'REPORTS') {
                        canAddToPortfolio = !!row.company_uuid;
                        companyUUID = row.company_uuid;
                        rowId = row.company_uuid;
                      }

                      if (tableId === 'BATCH_REPORTS') {
                        rowId = row.id;
                        canAddToPortfolio = !!row.id;
                      }

                      const menuOptionProps: MenuOptionProps = {
                        label: ellipsisLabel,
                        // report id
                        id: row.id,
                        // companyId to link to a company page
                        companyId: row?.company?.id || row?.company_id,
                        // companyUUID refers to an indexed company
                        companyUUID,
                        // below values to generate a report from a table
                        accounts_type: row?.company?.accounts_type,
                        currency: row?.company?.currency,
                        iso_code: row?.company?.iso_code,
                        canAddToPortfolio,
                        created_at: row?.created_at,
                        selectedRows: selectedRows?.length || 0,
                        row,
                      };

                      return (
                        <TableCell
                          key={`${i}-${row.id}`}
                          rowId={rowId}
                          className={classNames(
                            width,
                            selector !== 'MENU' &&
                              'whitespace-nowrap truncate text-ellipsis'
                          )}
                          contentClassName={contentClass}
                          rowLink={link}
                          align={contentAlignment}
                          title={title}
                          type={cellType}
                          disableCheckbox={!canAddToPortfolio}
                          showReportIcon={tableId === 'ALERTS' && row.report_id}
                          // info for the sub menu
                          {...(menuOptions && {
                            menuOptions: menuOptions(menuOptionProps),
                          })}
                          // info for the checkbox
                          {...(onCheck && {
                            onCheck: e =>
                              handleCheck(e.currentTarget.checked, row),
                          })}
                          playwrightId={setPlaywrightId(
                            `body.row-${rowIndex}.cell-${i}`
                          )}
                          rowIndex={rowIndex}
                          totalRows={data.length + blankRows}
                          row={row}
                          alertType={alertType}
                        >
                          {truncateLength
                            ? truncate(value, { length: truncateLength })
                            : value}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              ))}

            {/* These are fill rows not loading rows */}
            {!isLoading &&
              tableTotal > 0 &&
              fillEmptyRows &&
              blankRows > 0 &&
              blankRows <= rowLimit && (
                <SkeletonRow
                  cellQty={headers.length}
                  className={classNames(
                    'bg-gray-200',
                    compact ? 'h-[35px]' : 'h-[48px]'
                  )}
                  rowQty={blankRows}
                />
              )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          page={page}
          maxPages={maxPages || 0}
          total={tableTotal}
          rowLimit={rowLimit}
          handlePageChange={handlePageChange}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
          playwrightId={setPlaywrightId('pagination-bar')}
        />
      )}
    </div>
  );
};

export default Body;
