import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { IOption, PlaywrightProps, TranslateInput } from '../../types/global';
import { SimpleValue } from '../sme-calc-sections/AdvancedSearch';
import { usePlaywright } from '../../hooks/usePlaywright';
import classNames from 'classnames';
import { truncate } from 'lodash';

interface SelectMenuProps extends PlaywrightProps {
  defaultValue?: TranslateInput | SimpleValue;
  values?: SimpleValue[];
  selectedValue?: SimpleValue | IOption;
  setSelectedValue: (value: SimpleValue | IOption) => void;
  disabled?: boolean;
  className?: string;
  truncateLength?: number;
  placeholder?: TranslateInput;
}

const SelectMenu = ({
  values,
  selectedValue,
  setSelectedValue,
  defaultValue,
  disabled,
  playwrightId,
  className,
  truncateLength = 30,
  placeholder,
}: SelectMenuProps) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'select-menu');
  return (
    <Listbox
      value={selectedValue}
      onChange={setSelectedValue}
      disabled={disabled}
    >
      <div className={classNames(`mt-1 relative`, className)} {...id}>
        <Listbox.Button
          className={`${
            disabled && 'border-opacity-20 text-gray-400 shadow-none'
          } relative w-full border border-primary rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-highlight focus:border-highlight sm:text-sm min-h-[42px]`}
        >
          <span
            className={`block truncate`}
            {...setPlaywrightId(
              `${selectedValue ? selectedValue.optionName : defaultValue}`
            )}
            title={`${selectedValue ? selectedValue.optionName : defaultValue}`}
          >
            {truncate(
              `${
                selectedValue
                  ? selectedValue.optionName
                  : placeholder
                  ? placeholder
                  : defaultValue
              }`,
              {
                length: truncateLength,
              }
            )}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className={`${disabled && 'opacity-20'} h-6 w-6 text-primary`}
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {values?.map(value => (
              <Listbox.Option
                key={value.optionValue}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-highlight' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9'
                  )
                }
                value={value}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? 'font-semibold' : 'font-normal',
                        'flex items-center justify-start truncate'
                      )}
                      {...setPlaywrightId(`${value.optionName}`)}
                    >
                      {value.icon && <span>{value.icon}</span>}
                      {value.optionName}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-primary',
                          'absolute inset-y-0 right-0 flex items-center pr-4'
                        )}
                        {...setPlaywrightId(`${value.optionName}.checked`)}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SelectMenu;
