import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { TableContext } from './TableContext';

export interface HeaderProps {
  children?: ReactNode;
  className?: string;
}

const Header: FC<HeaderProps> = ({ children, className }) => {
  const { playwrightId } = React.useContext(TableContext);
  const [id] = usePlaywright(playwrightId || 'header');

  return (
    <div {...id} className="flex items-center w-full">
      <div
        className={classNames(
          'flex flex-col-reverse sm:flex-row sm:items-center justify-between w-full',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Header;
