import { ExclamationIcon } from '@heroicons/react/outline';
import React, { FC } from 'react';

import useModal from '../../hooks/useModal';
import { ITableCellProps } from '../../types/table';

/**
 * Should have access to all the row props to
 * use and pass whatever information is needed
 */
const FlagCell: FC<unknown & ITableCellProps['alertType']> = _props => {
  const { setModalData } = useModal();

  const isObject = typeof _props === 'object';

  const props = isObject && { ...(_props as object) };
  /**
   * flag will be replaced with the valid type
   */

  const isPortfolio =
    isObject && 'alertType' in props && props['alertType'] === 'ALERT_BREACH';

  const isCodat =
    isObject &&
    'alertType' in props &&
    props['alertType'] === 'CODAT_MONITORING';

  const arePropsValid =
    isObject &&
    'integration_error' in props &&
    props['integration_error'] !== null &&
    props['integration_error'] !== undefined;

  if (!isObject || !arePropsValid || (!isPortfolio && !isCodat)) {
    return null;
  }

  const modalType = isPortfolio ? 'ALERT_BREACH' : 'CODAT_MONITORING';

  const handleClick = () => {
    setModalData({
      modalType,
      data: {
        ...(props as object),
      },
    });
  };

  return (
    <div className="h-full w-full flex items-center justify-center pointer-events-none mx-1">
      <ExclamationIcon
        className="h-4 w-4 text-red-500 pointer-events-auto cursor-pointer"
        onClick={() => handleClick()}
      />
    </div>
  );
};

export default FlagCell;
