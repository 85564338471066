import React, { ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { QueryParams } from '../../lib/utils/http-helpers';
import { PlaywrightProps } from '../../types/global';
import Action, { ActionProps } from './Action';
import Actions, { ActionsProps } from './Actions';
import Body, { BodyProps } from './Body';
import Header, { HeaderProps } from './Header';
import Search, { SearchProps } from './Search';
import Sort, { SortProps } from './Sort';
import { TableContext } from './TableContext';

export interface TableProps extends PlaywrightProps {
  query?: QueryParams;
  handleQuery?: (queryObj: QueryParams) => void;
  theme?: 'dark' | 'light';
}

interface ChildComponents {
  Body: React.FC<BodyProps>;
  Header: React.FC<HeaderProps>;
  Search: React.FC<SearchProps>;
  Sort: React.FC<SortProps>;
  Actions: React.FC<ActionsProps>;
  Action: React.FC<ActionProps>;
}

export type ITable = React.FC<TableProps & { children?: ReactNode }> &
  ChildComponents;

const Table: ITable = ({
  children,
  playwrightId,
  query,
  handleQuery,
  theme,
}) => {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [id] = usePlaywright(playwrightId || 'table');

  return (
    <TableContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        playwrightId: id,
        query,
        handleQuery,
        theme,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

// HEADER
Table.Header = Header;
Table.Search = Search;
Table.Sort = Sort;
Table.Actions = Actions;
Table.Action = Action;

// BODY
Table.Body = Body;

export default Table;
