import { Menu as HeadlessMenu } from '@headlessui/react';
import { DocumentTextIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { FC } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';

import type { MenuOption } from '../../types/table';
import type { PlaywrightProps } from '../../types/global';

interface MenuProps extends PlaywrightProps {
  menuOptions: MenuOption[];
  topAligned?: boolean;
  showReportIcon?: boolean;
}

const Menu: FC<MenuProps> = ({
  playwrightId,
  menuOptions,
  topAligned = true,
  showReportIcon,
}) => {
  const [, setPlaywrightId] = usePlaywright(playwrightId);

  return (
    <HeadlessMenu as="div" className="relative inline-block text-left">
      {showReportIcon && (
        <div className="absolute left-0 top-1 -translate-x-full z-50">
          <DocumentTextIcon className="h-5 w-5 text-highlight" />
        </div>
      )}
      <div {...setPlaywrightId('menu')}>
        <HeadlessMenu.Button className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-200">
          <div className="w-4">
            <DotsVerticalIcon />
          </div>
        </HeadlessMenu.Button>
        <HeadlessMenu.Items
          className={classNames(
            'absolute right-0 w-64  bg-white rounded-md shadow-lg z-front ring-1 ring-black ring-opacity-5 focus:outline-none border-1 border-highlight',
            topAligned
              ? 'top-8 origin-top-right'
              : 'bottom-8 origin-bottom-right'
          )}
          {...setPlaywrightId('menu.options')}
        >
          {menuOptions?.map(
            ({ label, onClick, icon, disabled, title = '' }, i) => {
              return (
                <div
                  className="flex items-center h-12"
                  key={`table-cell-menu-${i}`}
                >
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        {...(!disabled && { onClick })}
                        disabled={disabled}
                        onKeyPress={onClick}
                        className={classNames(
                          'text-left group flex justify-evenly w-full h-full items-center px-2 py-2 text-xs cursor-pointer z-50 bg-white p-2 rounded font-bold shadow  ',
                          active && 'text-highlight',
                          disabled &&
                            'text-gray-300 cursor-default pointer-events-none'
                        )}
                        tabIndex={i}
                        {...setPlaywrightId(`menu.option-${i}`)}
                        title={title}
                      >
                        {icon && <span className="mr-4">{icon}</span>}
                        <span className="w-full">{label}</span>
                      </button>
                    )}
                  </HeadlessMenu.Item>
                </div>
              );
            }
          )}
        </HeadlessMenu.Items>
      </div>
    </HeadlessMenu>
  );
};

export default Menu;
