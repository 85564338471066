import { XCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { TableContext } from './TableContext';

export interface ActionsProps {
  className?: string;
  children?: ReactNode;
}

const Actions: FC<ActionsProps> = ({ className, children }) => {
  const { selectedRows, setSelectedRows, playwrightId } =
    React.useContext(TableContext);
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'actions');

  return (
    <div
      {...setPlaywrightId('actions')}
      className={classNames(
        'flex flex-1 items-center justify-end sm:gap-x-4 h-16',
        className
      )}
    >
      <div className="flex items-center w-auto">
        {selectedRows?.length > 0 && (
          <div className="flex items-center justify-end w-auto px-1 my-2 mr-2 gap-x-2">
            <span className="text-sm text-gray-500">
              {selectedRows?.length} selected
            </span>
            <XCircleIcon
              onClick={() => setSelectedRows([])}
              className="h-7 w-7 hover:text-highlight cursor-pointer"
              {...setPlaywrightId('clear-svg')}
            />
          </div>
        )}
      </div>
      <div className="flex items-center w-auto gap-x-2">{children}</div>
    </div>
  );
};

export default Actions;
