import classNames from 'classnames';
import { FC } from 'react';

import { ITableCellProps } from '../../types/table';

const CellWrapper: FC<
  Pick<
    ITableCellProps,
    'contentClassName' | 'align' | 'children' | 'rowLink'
  > & {
    wrapperClassName?: string;
  }
> = ({ children, rowLink, align, contentClassName, wrapperClassName }) => {
  return (
    <div
      data-testid="table-cell"
      className={classNames(
        wrapperClassName,
        'w-full h-full',
        rowLink && 'group-hover:cursor-pointer group-hover:text-highlight'
      )}
    >
      <div className={`flex ${align}`}>
        <div className={contentClassName}>{children}</div>
      </div>
    </div>
  );
};
export default CellWrapper;
