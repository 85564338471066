import classNames from 'classnames';
import React from 'react';

import { PlaywrightProps } from '../../types/global';

export interface ITableRowProps extends PlaywrightProps {
  className?: string;
  newClassName?: string;
  children?: React.ReactNode;
  title?: string;
  rowIndex?: number;
  id?: string;
  compact?: boolean;
  withHover?: boolean;
}

const TableRow = ({
  className,
  newClassName,
  children,
  rowIndex,
  playwrightId,
  compact,
  id,
  withHover = true,
}: ITableRowProps) => {
  return (
    <tr
      data-testid={`table-row-${rowIndex}`}
      {...playwrightId}
      title="table-row"
      className={classNames(
        newClassName
          ? newClassName
          : `${className} min-w-full bg-white group active-row`,
        compact ? 'h-[35px] text-xs' : 'h-[48px] font-semibold',
        {
          'hover:bg-gray-50': withHover,
        }
      )}
      id={id}
    >
      {children}
    </tr>
  );
};

export default TableRow;
