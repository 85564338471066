import React, { FC } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import SearchBar from '../reports-search/SearchBar';
import { TableContext } from './TableContext';
import { QueryParams } from '../../lib/utils/http-helpers';
import classNames from 'classnames';

export interface SearchProps {
  className?: string;
  placeholder?: string;
  query?: QueryParams;
  handleQuery?: (queryObj: QueryParams) => void;

  search?: QueryParams;
  setSearch?: (queryObj: QueryParams) => void;
}

/**
 * @note in some case, we may want to use a different endpoint to search,
 * i.e. on /reports where we have org reports and user reports, but when
 * the user searches we use the /reports instead. In this case, `search`
 * and `setSearch` should be used with the correct query params instance
 */

const Search: FC<SearchProps> = ({
  className,
  placeholder,
  search,
  setSearch,
}) => {
  const { playwrightId, query, handleQuery } = React.useContext(TableContext);
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'search');

  const searchQuery = search?.search ?? query?.search ?? '';
  const setSearchQuery = (x: string) => {
    if (setSearch) {
      setSearch({ search: x });
    } else {
      handleQuery?.({ search: x });
    }
  };

  return (
    <div
      {...id}
      className={classNames('w-full sm:w-1/2 my-3 sm:my-0', className)}
    >
      <SearchBar
        search={searchQuery}
        setSearch={setSearchQuery}
        playwrightId={setPlaywrightId('search-bar')}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Search;
