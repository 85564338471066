import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid';
import React from 'react';
import { usePlaywright } from '../../hooks/usePlaywright';
import { PlaywrightProps } from '../../types/global';
import { useTranslations } from 'next-intl';

interface PaginationButtonProps extends PlaywrightProps {
  active?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  hoverClassName?: string;
  testid?: string;
}

const PaginationButton = ({
  active,
  onClick,
  children,
  hoverClassName,
  testid,
  playwrightId,
}: PaginationButtonProps) => {
  const [_playwrightId] = usePlaywright(playwrightId);
  return (
    <button
      {..._playwrightId}
      data-testid={testid}
      type="button"
      className={`${
        active
          ? 'bg-highlight bg-opacity-30 text-highlight btnx-active'
          : 'bg-white'
      } ${
        hoverClassName
          ? hoverClassName
          : 'hover:bg-highlight hover:bg-opacity-30 hover:text-highlight'
      } flex text-center justify-center items-center h-full min-w-[40px] cursor-pointer px-1`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export interface PaginationBarProps extends PlaywrightProps {
  page: number;
  maxPages: number;
  total: number;
  rowLimit: number;
  handlePageChange: (page: number) => void;
  handlePageUp: () => void;
  handlePageDown: () => void;
}

/**
 * sort: str = "id",
 * order: str = "desc",
 * start: NonNegativeInt | None = None,
 * end: NonNegativeInt | None = None,
 * search: str | None = None,
 */

const PaginationBar = ({
  page,
  maxPages,
  total,
  rowLimit,
  handlePageChange,
  handlePageDown,
  handlePageUp,
  playwrightId,
}: PaginationBarProps) => {
  const t = useTranslations();
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'pagination', true);
  const chevronAvailableClass = 'hover:bg-highlight hover:bg-opacity-30';
  const chevronEndClass =
    'bg-gray-300 bg-opacity-100 opacity-40 text-primary pointer-events-none';

  return (
    <div
      className="flex flex-col-reverse items-end justify-between gap-2 my-2 bg-transparent md:flex-row md:items-center"
      {...playwrightId}
    >
      <p className="text-xs text-primary md:text-sm">
        Showing{' '}
        <span className="font-medium">
          {total > 0 ? page * rowLimit - rowLimit + 1 : 0}
        </span>{' '}
        to{' '}
        <span className="font-medium">{Math.min(rowLimit * page, total)}</span>{' '}
        of <span className="font-medium">{total}</span> {t('results')}
      </p>
      <div className="flex items-center overflow-hidden text-xs text-gray-400 rounded h-9 md:text-sm w-min">
        <PaginationButton
          testid="go-to-start"
          onClick={() => handlePageChange(1)}
          hoverClassName={`bg-primary bg-opacity-30 text-primary  ${
            page > 1 ? chevronAvailableClass : chevronEndClass
          }`}
          playwrightId={setPlaywrightId('go-to-start')}
        >
          <ChevronDoubleLeftIcon className="w-8 h-5" />
        </PaginationButton>
        <PaginationButton
          testid="go-to-prev"
          onClick={handlePageDown}
          hoverClassName={` ${
            page > 1 ? chevronAvailableClass : chevronEndClass
          }`}
          playwrightId={setPlaywrightId('go-to-prev')}
        >
          <ChevronLeftIcon className="w-8 h-5" />
        </PaginationButton>
        {[...Array(maxPages)]
          .map((_, index) => (
            <PaginationButton
              testid={`page-${index}`}
              active={page === index + 1}
              onClick={() => handlePageChange(index + 1)}
              key={`page-${index}`}
              playwrightId={setPlaywrightId(`go-to-page-${index}`)}
            >
              {index + 1}
            </PaginationButton>
          ))
          .slice(
            Math.max(
              page - (page > maxPages - 3 ? Math.abs(page + 5 - maxPages) : 3),
              0
            ),
            Math.min(page + (page < 3 ? 5 - page : 2), maxPages)
          )}
        <PaginationButton
          testid="go-to-next"
          onClick={handlePageUp}
          hoverClassName={` ${
            page < maxPages ? chevronAvailableClass : chevronEndClass
          }`}
          playwrightId={setPlaywrightId('go-to-next')}
        >
          <ChevronRightIcon className="w-8 h-5" />
        </PaginationButton>
        <PaginationButton
          testid="go-to-end"
          onClick={() => handlePageChange(maxPages)}
          hoverClassName={`bg-primary bg-opacity-30 text-primary  ${
            page < maxPages ? chevronAvailableClass : chevronEndClass
          }`}
          playwrightId={setPlaywrightId('go-to-end')}
        >
          <ChevronDoubleRightIcon className="w-8 h-5" />
        </PaginationButton>
      </div>
    </div>
  );
};

export default PaginationBar;
