import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { useTranslations } from 'next-intl';
import React, { useRef } from 'react';
import { PlaywrightProps } from '../../types/global';
import { usePlaywright } from '../../hooks/usePlaywright';

interface SearchBarProps extends PlaywrightProps {
  search: string;
  setSearch: (x: string) => void;
  placeholder?: string;
}

const SearchBar = ({
  search,
  setSearch,
  playwrightId,
  placeholder,
}: SearchBarProps) => {
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'search-bar');
  const t = useTranslations();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex items-center justify-between max-w-xl gap-2" {...id}>
      <div className="flex items-center justify-between w-full h-10 pr-2 overflow-hidden bg-white border rounded-lg border-secondary/30">
        <SearchIcon
          className="w-5 h-5 ml-2 min-w-[1.25rem] min-h-[1.25rem]"
          {...setPlaywrightId('search-icon-svg')}
        />
        <input
          ref={inputRef}
          type="search"
          className="w-full border-none appearance-none focus:ring-0 text-sm"
          placeholder={placeholder || t('search_placeholder')}
          defaultValue={search}
          onChange={e => {
            if (
              e.target.value.length > 2 ||
              e.target.value.length < search.length
            ) {
              debounce(() => setSearch(e.target.value), 500)();
            }
          }}
          {...setPlaywrightId('search-input')}
        />
        {search && (
          <button
            className="text-xs w-min grow"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = '';
                setSearch('');
              }
            }}
            {...setPlaywrightId('clear-button')}
          >
            {t('clear')}
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
