import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/outline';
import React, { FC, ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import Button, { ButtonProps } from '../elements/Button';
import { TableContext } from './TableContext';
import classNames from 'classnames';

export type ActionType =
  | 'ADD_TO_PORTFOLIO'
  | 'REMOVE_FROM_PORTFOLIO'
  | 'ADD_BATCH'
  | 'REMOVE_BATCH';

export interface ActionProps {
  children?: ReactNode;
  type?: ActionType;
  onClick?: (selectedRows: string[]) => void;
  isIcon?: boolean;
  btnClassName?: string;
}

interface ActionButtonProps {
  variant: ButtonProps['variant'];
  label: ReactNode;
}

const Action: FC<ActionProps> = ({
  type = 'ADD_TO_PORTFOLIO',
  onClick,
  isIcon = true,
  btnClassName,
}) => {
  const { selectedRows, playwrightId } = React.useContext(TableContext);
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'action');

  const props: Record<ActionType, ActionButtonProps> = {
    ADD_TO_PORTFOLIO: {
      label: (
        <PlusCircleIcon
          className="h-8 w-6"
          {...setPlaywrightId(`action.${type}.plus-svg`)}
        />
      ),
      variant: 'highlight',
    },
    REMOVE_FROM_PORTFOLIO: {
      label: (
        <MinusCircleIcon
          className="h-8 w-6"
          {...setPlaywrightId(`action.${type}.minus-svg`)}
        />
      ),
      variant: 'secondary',
    },
    ADD_BATCH: {
      label: (
        <span className="!w-auto flex items-center justify-between">
          <PlusCircleIcon
            className="h-8 w-6 mr-2"
            {...setPlaywrightId(`action.${type}.plus-svg`)}
          />
          All
        </span>
      ),
      variant: 'highlight',
    },
    REMOVE_BATCH: {
      label: (
        <span className="!w-auto flex items-center justify-between">
          <MinusCircleIcon
            className="h-8 w-6 mr-2"
            {...setPlaywrightId(`action.${type}.minus-svg`)}
          />
          All
        </span>
      ),
      variant: 'secondary',
    },
  };

  return (
    <Button
      className={classNames(btnClassName, '!rounded-md')}
      variant={props[type].variant}
      onClick={() => onClick?.(selectedRows)}
      disabled={
        type === 'ADD_BATCH' || type === 'REMOVE_BATCH'
          ? false
          : !selectedRows?.length
      }
      playwrightId={setPlaywrightId(`action.${type}`)}
      isIcon={isIcon}
    >
      {props[type].label}
    </Button>
  );
};

export default Action;
