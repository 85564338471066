import classNames from 'classnames';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import React, { FC, ReactNode, useContext } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import type { ITableCellProps } from '../../types/table';
import Menu from '../elements/Menu';
import CellWrapper from './CellWrapper';
import FlagCell from './FlagCell';
import { TableContext } from './TableContext';

const ContentComponent: FC<Pick<ITableCellProps, 'children' | 'rowLink'>> = ({
  children,
  rowLink,
}) =>
  rowLink ? (
    <Link href={`${rowLink}`} data-testid="content-link">
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  );

const TableCell = ({
  className,
  contentClassName,
  rowLink,
  align = 'text-left justify-start',
  children,
  title,
  type = 'DATA',
  menuOptions,
  rowIndex,
  totalRows,
  playwrightId,
  showReportIcon,
  disableCheckbox,
  rowId,
  onCheck,
  row,
  alertType,
}: ITableCellProps) => {
  let content: ReactNode;
  const t = useTranslations();
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'cell', true);
  const { selectedRows, setSelectedRows } = useContext(TableContext);

  if (type === 'DATA') {
    content = (
      <ContentComponent rowLink={rowLink}>
        <CellWrapper
          rowLink={rowLink}
          align={align}
          contentClassName={`overflow-x-hidden ${contentClassName}`}
        >
          {children}
        </CellWrapper>
      </ContentComponent>
    );
  }

  if (type === 'CHECKBOX' && rowId) {
    const isChecked = selectedRows.includes(`${rowId}`);

    const handleCheck: React.MouseEventHandler<HTMLInputElement> = e => {
      onCheck?.(e);
      if (isChecked) {
        setSelectedRows((prev: string[]) => [
          ...new Set(prev.filter(id => id !== `${rowId}`)),
        ]);
      } else {
        setSelectedRows((prev: string[]) => [...new Set([...prev, rowId])]);
      }
    };

    content = (
      <CellWrapper
        align={align}
        contentClassName={`overflow-x-hidden ${contentClassName}`}
      >
        <input
          type="checkbox"
          name=""
          id=""
          checked={isChecked}
          className={classNames(
            'cursor-pointer focus:ring-0 checked:bg-highlight focus:checked:bg-highlight ',
            disableCheckbox
              ? 'opacity-50 bg-slate-200 border-gray-300 cursor-default'
              : 'hover:checked:bg-highlight/50'
          )}
          onClick={handleCheck}
          disabled={disableCheckbox}
          title={disableCheckbox ? t('no_uuid') : 'Select row'}
          {...setPlaywrightId('checkbox')}
        />
      </CellWrapper>
    );
  }

  if (type === 'MENU') {
    const alignLimit = Number(totalRows) - Number(menuOptions?.length);
    content = (
      <CellWrapper
        align={align}
        contentClassName={` ${contentClassName} relative`}
      >
        <Menu
          playwrightId={playwrightId}
          menuOptions={menuOptions || []}
          topAligned={Number(rowIndex) < alignLimit}
          showReportIcon={showReportIcon}
        />
      </CellWrapper>
    );
  }

  if (type === 'ALERT') {
    content = (
      <CellWrapper
        align={align}
        contentClassName={` ${contentClassName} relative`}
      >
        <FlagCell {...row} alertType={alertType} />
      </CellWrapper>
    );
  }

  return (
    <td className={`${className}`} title={title} {...playwrightId}>
      {content}
    </td>
  );
};

export default TableCell;
