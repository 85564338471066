/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-switch */
import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { TranslateInput } from '../../types/global';
import { OrderTypes, SortTypes, TableIdsType } from '../../types/table';
import SelectMenu from '../elements/SelectMenu';
import { SimpleValue } from '../sme-calc-sections/AdvancedSearch';
import { TableContext } from './TableContext';

export interface SortProps {
  filterPlaceholder?: TranslateInput;
  filterOptions?: Array<SimpleValue>;
  order?: OrderTypes | null;
  setOrder?: Dispatch<SetStateAction<OrderTypes | null>>;
  sort?: SortTypes | null;
  setSort?: Dispatch<SetStateAction<SortTypes | null>>;
  tableId?: TableIdsType;
  className?: string;
}

const Sort: FC<SortProps> = ({
  filterOptions,
  filterPlaceholder,
  tableId,
  className,
}) => {
  const { playwrightId, query, handleQuery } = React.useContext(TableContext);
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'sort-by');

  const handleFilterChange = (option: SimpleValue) => {
    switch (option.optionValue) {
      case 'batch_name_asc':
      case 'company_asc':
      case 'portfolio_asc':
        if (tableId === 'REPORTS') {
          handleQuery?.({ sort: 'company_name', order: 'asc' });
        } else {
          handleQuery?.({ sort: 'name', order: 'asc' });
        }
        break;
      case 'batch_name_desc':
      case 'company_desc':
      case 'portfolio_desc':
        if (tableId === 'REPORTS') {
          handleQuery?.({ sort: 'company_name', order: 'desc' });
        } else {
          handleQuery?.({ sort: 'name', order: 'desc' });
        }
        break;
      // batch doesn't change the query and refetch
      case 'batch_company_name_asc':
        handleQuery?.({ sort: 'company_name', order: 'asc' });
        break;
      // batch doesn't change the query and refetch
      case 'batch_company_name_desc':
        handleQuery?.({ sort: 'company_name', order: 'desc' });
        break;
      case 'most_recently_created':
        handleQuery?.({ sort: 'created_at', order: 'desc' });
        break;
      case 'least_recently_created':
        handleQuery?.({ sort: 'created_at', order: 'asc' });
        break;
      default:
        handleQuery?.({ sort: '', order: 'asc' });
        break;
    }
  };

  const selectedFilters = (
    placeholder?: TranslateInput
  ): SimpleValue | undefined => {
    const sort = query?.sort;
    const order = query?.order;

    if (!filterOptions || !sort || !order) {
      if (placeholder) {
        return { optionValue: 'placeholder', optionName: `${placeholder}` };
      }
      return filterOptions?.[0];
    }

    const filterKey = (() => {
      switch (sort) {
        case 'name':
          switch (tableId) {
            case 'COMPANIES':
              return order === 'asc' ? 'company_asc' : 'company_desc';
            case 'PORTFOLIOS':
              return order === 'asc' ? 'portfolio_asc' : 'portfolio_desc';
            case 'BATCH_REPORTS':
              return order === 'asc' ? 'batch_name_asc' : 'batch_name_desc';
            default:
              return null;
          }
        case 'company_name':
          switch (tableId) {
            case 'REPORTS':
              return order === 'asc' ? 'company_asc' : 'company_desc';
            case 'COMPANIES':
              return order === 'asc'
                ? 'batch_company_name_asc'
                : 'batch_company_name_desc';
            default:
              return null;
          }
        case 'created_at':
          return order === 'asc'
            ? 'least_recently_created'
            : 'most_recently_created';
        default:
          return null;
      }
    })();

    return filterKey
      ? filterOptions.find(opt => opt.optionValue === filterKey) ||
          filterOptions[0]
      : filterOptions[0];
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-center w-[150px] sm:w-[230px]',
        className
      )}
      {...setPlaywrightId('sort-by')}
    >
      <SelectMenu
        className="w-[99%] !m-0 bg-white"
        playwrightId={setPlaywrightId('filter')}
        setSelectedValue={handleFilterChange}
        selectedValue={selectedFilters(filterPlaceholder)}
        defaultValue={filterOptions?.[0]}
        values={filterOptions}
        truncateLength={20}
      />
    </div>
  );
};

export default Sort;
