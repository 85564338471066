import React from 'react';
import { PlaywrightAttr } from '../../types/global';
import { QueryParams } from '../../lib/utils/http-helpers';
// import { ITableRowProps } from './TableRow';

export interface ITableContext {
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  playwrightId?: PlaywrightAttr;
  query?: QueryParams;
  handleQuery?: (queryObj: QueryParams) => void;
  theme?: 'light' | 'dark';
}

export const TableContext = React.createContext<ITableContext>({
  selectedRows: [],
  setSelectedRows: () => {},
  playwrightId: undefined,
  query: undefined,
  handleQuery: () => {},
  theme: 'light',
});
